@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  font-size: 62.5%;
}
h1{
  color: white;
  text-indent: 10px;
  letter-spacing: 5px;
  white-space: nowrap;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}


.whoIsWatching {
  width: 100vw;
  min-height: 100vh;
  background-color: #141414;
}

.logo-section {
  width: 95vw;
  margin: auto;
  height: 7rem;
  background-color: rgb(20, 20, 20);
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-section a img {
  width: 10rem;
  height: 4rem;
  cursor: pointer;
}

.main-div {
  width: 100vw;
  height: 80vh;
  /* background-color: rosybrown; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.main-div h2 {
  font-size: 6.5rem;
  letter-spacing: 0.2rem;
  font-size: 600;
}

.memberDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.memberDiv .btn,
.addIcon {
  height: 10vw;
  min-height: 8.4rem;
  max-height: 20rem;
  width: 10vw;
  min-width: 8.4px;
  max-width: 20rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  margin-top: 4rem;
  margin-right: 3.5rem;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}

.user-btn .btn {
  background-image: url("/public/images/landing-page/user1.png") !important;
}

.user-btn .btn-2 {
  background-image: url("/public/images/landing-page/user2.png") !important;
}

.memberDiv .btn-3 {
  background-image: url("/public/images/landing-page/user3.png") !important;
}

.memberDiv .btn:hover {
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.9);
}

.user-btn {
    position: relative;
    display: flex;
    flex-direction: column;
}

.user-btn span {
    width: inherit;
    position: absolute;
    bottom: -3.5rem;
    left: 0;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    font-size: 2rem;
  }

.fa-plus-circle {
  font-size: 7vw;
  color: grey;
  opacity: 0.8;
}

.addIcon {
  background-color: transparent;
}

.addIcon:hover {
  background-color: #fff;
}

.addicon:hover > .fa-plus-circle {
  opacity: 1;
}

.manageProfile {
  border: 1px solid grey;
  color: grey;
  text-transform: uppercase;
  padding: 0.8rem 2.6rem;
  letter-spacing: 0.5rem;
  font-size: 2.5rem;
  margin-top: 12rem;
  background-color: transparent;
  cursor: pointer;
}
.manageProfile:hover {
  border: 1px solid #fff;
  color: #fff;
}
