.dropdown-toggle, .dropdown-item {
    font-size: 13px !important;
}

.modal-body-text {
    font-size: 14px !important;
}

.form-title {
    font-weight: bold;
    font-size: 13px !important;
    color: #000 !important;
}

/* Map css */
#google-map {
  width: 100%;
  height: 100%;
}
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 80vh;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }